import { graphql, useStaticQuery } from 'gatsby'
export const getAllServices = (): Service.RootObject[] => {
	const {
		strapiHome: {services },
	} = useStaticQuery(graphql`
		query allServices {
			strapiHome {
				services {
					service {
					name
					description
					image {
						childImageSharp {
							gatsbyImageData(
								width: 650
								height: 650
								placeholder: BLURRED
								formats: [AUTO, WEBP, AVIF]
								layout: FULL_WIDTH
								aspectRatio: 1
								transformOptions: { cropFocus: CENTER }
							)
						}
					}
					}
				}
			}
		}
	`)

	return services.map(service => service.service)
}
