import React from 'react'
import { Services } from '../components/Services/Services'
import { Container } from '../components/Shared/Container/Container'
import Layout from '../components/Shared/Layout/Layout'
import { PageHeader } from '../components/Shared/PageHeader/PageHeader'
import { SEO } from '../components/Shared/SEO/SEO'

const ServicesPage = () => (
	<Layout>
		<SEO
			description="V našej privátnej očnej ambulancii poskytujeme komplexné očné vyšetrenia a starostlivosť o zrak."
			title="Naše služby"
		/>
		<PageHeader
			pageTitle="Naše služby"
			pageIntroText="V našej privátnej očnej ambulancii poskytujeme komplexné očné vyšetrenia a starostlivosť o zrak."
			buttonText="Dohodnúť si termín"
			buttonUrl="/kontakt"
		/>
		<Services />
	</Layout>
)

export default ServicesPage
