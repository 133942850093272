import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { createServiceSlug } from '../hooks/createServiceSlug'

export interface ServiceProps {
	className?: string
	name: string
	image: IGatsbyImageData
	description: string
	infoUrl?: string
	imageLeft: boolean
}

export const Service = (props: ServiceProps) => {
	const { name, description, image, imageLeft } = props
	const imageM = getImage(image)

	return (
		<div className={`grid md:grid-cols-2  grid-cols-1 md:gap-x-8 gap-y-14 md:gap-y-0`}>
			<div
				id={createServiceSlug(name)}
				className={` ${
					imageLeft ? 'md:order-2 md:pl-10 ' : 'md:order-1'
				} flex justify-center items-start flex-col`}
			>
				<h2 className="text-black text-4xl mb-8">{name}</h2>
				<ReactMarkdown children={description} className="font-primary ml-4 " />
			</div>

			<div className={` ${imageLeft ? 'md:order-1' : 'md:order-2'}`}>
				<div className="mx-auto ">
					<GatsbyImage
						image={imageM}
						alt={name}
						title="Očna ambulancia Banská Bystrica"
						objectFit="fill"
						className={`block ${
							imageLeft ? 'shadow-blue' : 'shadow-green'
						} md:max-w-lg sm:max-w-sm max-w-xs mx-auto`}
					/>
				</div>
			</div>
		</div>
	)
}
