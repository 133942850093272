import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import { Button } from '../Shared/Button/Button'
import { Container } from '../Shared/Container/Container'
import { Service } from './components/Service'
import { getAllServices } from './hooks/getAllServices'
import cennikFile from '../../assets/documents/ocna_ambulancia_cennik.pdf'

export interface ServicesProps {
	className?: string
}

export const Services = (props: ServicesProps) => {
	const services = getAllServices()

	return (
		<Container className="flex md:space-y-52  space-y-20 flex-col pb-48 ">
			{services.map((service, index: number) => (
				<Service
					name={service.name}
					description={service.description}
					image={service.image}
					imageLeft={index % 2 !== 0}
					key={index}
				/>
			))}
			<div className="flex flex-col justify-center items-center space-y-6">
				<h2 className="text-darkBlue text-4xl mx-auto">Cenník</h2>
				<a href={cennikFile} download target="_blank">
					<Button>Kompletný cenník na stiahnutie (.pdf)</Button>
				</a>
			</div>
		</Container>
	)
}
